import {
	requests
}from '../../../api/agent'

export default {
	downloadNotificationFile : (params) => requests.getFile(`/applicantNotifications/downloadNotificationFile`, params),
	getCountNotRead          : () => requests.get(`/applicantNotifications/getCountNotRead`),
	getFirstNotifications    : (params) => requests.getWithParams(`/applicantNotifications/getFirstNotifications`, params),
	getPagedNotifications    : (params) => requests.post(`/applicantNotifications/getPagedNotifications`, params),
	readAllNotifications	 : () => requests.get(`/applicantNotifications/readAllNotifications`)
}