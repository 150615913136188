const i18n = {
	clickNotifications: {
		0 : "Націсніце для пераходу на адпаведную старонку",
		1 : "Нажмите для перехода на соответствующую страницу"
	},
	headerNotifications: {
		0 : "Апавяшчэнні",
		1 : "Уведомления"
	},
	isEmpty: {
		0 : "Апавяшчэнні адсутнічаюць.",
		1 : "Уведомления отсутствуют."
	},
	link: {
		0 : "Перайсці на старонку",
		1 : "Перейти на страницу"
	},
	newNotifications: {
		0 : "Новых апавяшчэнняў: {0}",
		1 : "Новых уведомлений: {0}"
	},
	noneNotifications: {
		0 : "Непрачытаныя апавяшчэнні адсутнічаюць.",
		1 : "Непрочитанные уведомления отсутствуют."
	},
	placeholderItems: {
		0 : "Пачніце ўводзіць змест апавяшчэння",
		1 : "Начните вводить содержание уведомления"
	},
	checkboxIsRead: {
		0 : "Паказваць толькі непрачытаныя",
		1 : "Показывать только непрочитанные"
	},
	inputSearch: {
		0 : "Пошук",
		1 : "Поиск"
	},
	tabNames: {
		0: [
			"Усе апавяшчэнні",
			"Непрачытаныя апавяшчэнні",
			"Прачытаныя апавяшчэнні"
		],
		1: [
			"Все уведомления",
			"Непрочитанные уведомления",
			"Прочитанные уведомления"
		]
	},
	tableItems: {
		0: [
			"Тып",
			"Змест",
			"Пераход на старонку",
			"Дата"
		],
		1: [
			"Тип",
			"Содержание",
			"Переход на страницу",
			"Дата"
		]
	},
	unregisteredUser: {
		0 : "Неаўтарызаваны карыстальнік",
		1 : "Неавторизованный пользователь"
	},
	readAllNotification: {
		0 : "Пазначыць усё як прачытаныя",
		1 : "Пометить все как прочитанные"
	}
}

export default i18n
